<template>
    <div class="box">
        <el-steps :active="active" finish-status="success">
          <el-step title="选择商品"></el-step>
          <el-step title="填写基本信息"></el-step>
        
        </el-steps>
        <div class="onebu" v-show="active==0">
            <div class="one-t">选择商品:</div>
            <div class="one-box">
                <img src="../../assets/images/normal_u14.svg" alt="">
            </div>
        </div>
        <div class="twobu" v-show="active==1">
            <el-form ref="form" :model="form" label-width="100px">
              <el-row>
                <el-col :span="9">
                    <el-form-item label="店铺头像">
                        <el-upload
                            class="avatar-uploader"
                            :action="$uploadURL"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess1"
                            >
                            <img v-if="form.logoUrl" :src="form.logoUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item> 
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="9">
                    <el-form-item label="商品轮播图">
                        <el-upload
                            class="avatar-uploader"
                            :action="$uploadURL"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess1"
                            >
                            <img v-if="form.logoUrl" :src="form.logoUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item> 
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="9">
                    <el-form-item label="总购买数量限制">
                      <el-input v-model="form.businessAccount"></el-input>
                   </el-form-item>  
                </el-col>
               </el-row>
               <el-row>
                <el-col :span="9">
                    <el-form-item label="积分商城分类">
                        <el-select v-model="value" placeholder="请选择">
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                   </el-form-item>  
                </el-col>
               </el-row>
               <el-row>
                <el-col :span="9">
                    <el-form-item label="上架状态">
                        <el-radio v-model="radio" label="1">开启</el-radio>
                        <el-radio v-model="radio" label="2">关闭</el-radio>
                   </el-form-item>  
                </el-col>
               </el-row>
               <el-row>
                <el-col :span="9">
                    <el-form-item label="运费设置">
                        <el-radio v-model="radio" label="1">开启</el-radio>
                        <el-radio v-model="radio" label="2">关闭</el-radio>
                   </el-form-item>  
                </el-col>
               </el-row>
               <el-row>
                <el-col :span="9">
                    <el-form-item label="活动状态">
                        <el-switch
                          v-model="value1"
                          active-text="按月付费"
                          inactive-text="按年付费">
                        </el-switch>
                   </el-form-item>  
                </el-col>
               </el-row>
               <el-row>
                <el-col :span="24">
                    <el-form-item label="规格选择">
                        <el-table
                          :data="tableData"
                          style="width: 1000px"
                          height="calc(100vh - 380px)"
                          width = "100vw"
                          @selection-change="handleSelectionChange"
                          border
                          :header-cell-style="{
                            'background-color': '#f2f2f2',
                            'text-align': 'center',
                            border: '1px solid #ddd',
                          }"
                        >
                          <el-table-column type="selection" width="50"> </el-table-column>
                          <el-table-column
                            prop="categoryName"
                            label="颜色"
                           
                            align="center"
                          >
                          </el-table-column>
                          <el-table-column
                            prop="categoryName"
                            label="图片"
                           
                            align="center"
                          >
                          </el-table-column>
                          <el-table-column
                            prop="creatTime"
                            label="兑换积分"
                           
                            align="center"
                          >

                          </el-table-column>
                          <el-table-column
                            prop="creatTime"
                            label="价格"
                           
                            align="center"
                          >

                          </el-table-column>
                          <el-table-column
                            prop="creatTime"
                            label="库存"
                           
                            align="center"
                          >

                          </el-table-column>
                          <el-table-column
                            prop="creatTime"
                            label="商品尺寸"
                           
                            align="center"
                          >

                          </el-table-column>
                          <el-table-column
                            prop="creatTime"
                            label="重量"
                           
                            align="center"
                          >

                          </el-table-column>
                          <el-table-column
                            prop="creatTime"
                            label="体积"
                           
                            align="center"
                          >

                          </el-table-column>
                          <el-table-column
                            prop="creatTime"
                            label="商品编号"
                           
                            align="center"
                          >

                          </el-table-column>
                        </el-table>  
                   </el-form-item>  
                </el-col>
               </el-row>
               
               
              
        </el-form>
        </div>
        <div  style="margin-left: 100px;margin-top: 50px;">
            <el-button  style="margin-top: 12px;margin-right: 50px;" v-show="active==0">取消</el-button>
            <el-button type="primary" style="margin-top: 12px;" v-show="active==0" @click="next">下一步</el-button>
            <el-button  style="margin-top: 12px;margin-right: 50px;" v-show="active==1"  @click="text">上一步</el-button>
            <el-button type="primary"  style="margin-top: 12px;margin-right: 50px;" v-show="active==1" @click="submit">保存</el-button>
        </div>

        
    </div>
  </template>
  
  <script>
 export default {
 
  data() {
    return {
        active:0,
        form:{},
        options: [{
          value: '选项1',
          label: '黄金糕'
        }],
        value: '',
        radio: '1',
        value1:'',
        tableData:[{}]

    };
  },
  mounted() {
   
  },
  methods: {
    next() {
        if (this.active++ > 1) this.active = 0;
    },
    text(){
        if (this.active-- > 1) this.active = 0;
    },
    submit(){
        this.$router.push({path:'/integralsplist'})
    }
  }
};
  </script>
  
  <style lang="less" scoped>
  .onebu {
    display: flex;
    .one-t {

    }
    .one-box {
        width: 110px;
        height: 110px;
        padding: 2px 2px 2px 2px;
        border-radius: 5px;
        border: 1px solid #797979;
        box-sizing: border-box;
        img {
            width: 30px;
            height: 30px;
            margin-top: 35px;
            margin-left: 35px;
        }
    }
  }
  .box {
    background-color: #fff;
    // height: 100vh;
    padding: 20px 100px;
  }
  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #877b7b;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    // display: block;
  }
  </style>
  