import { render, staticRenderFns } from "./splistdetail.vue?vue&type=template&id=abc1f6c2&scoped=true"
import script from "./splistdetail.vue?vue&type=script&lang=js"
export * from "./splistdetail.vue?vue&type=script&lang=js"
import style0 from "./splistdetail.vue?vue&type=style&index=0&id=abc1f6c2&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abc1f6c2",
  null
  
)

component.options.__file = "splistdetail.vue"
export default component.exports